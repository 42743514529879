import _isValid from "./src/isValid";
import _isInvalid from "./src/isInvalid";
import _isAmbiguous from "./src/isAmbiguous";
import _isMissingSecondary from "./src/isMissingSecondary";
var exports = {};
exports = {
  isValid: _isValid,
  isInvalid: _isInvalid,
  isAmbiguous: _isAmbiguous,
  isMissingSecondary: _isMissingSecondary
};
export default exports;
export const isValid = exports.isValid;